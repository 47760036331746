import React from 'react';

function AboutContent() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          <div className="max-w-3xl mx-auto">
            <div className="text-lg text-slate-500">
              <h2 className="h3 font-playfair-display text-slate-800 mb-4">My Story</h2>
              <p></p>
              <p className="mb-8">
                As a highly experienced Project Engineer with 10 years of work in the Oil & Gas and Petrochemical plant industries in Saudi Arabia, I have acquired a wealth of knowledge and expertise in various aspects of project execution and management. My expertise extends to process operations and maintenance, pre-commissioning, construction EPC, preventive maintenance, plant shutdown and turnaround maintenance.                  I am highly proficient in using MS Office Suite of tools, which enhances my ability to effectively manage and execute projects. I have worked at SABIC and have affiliations with several other top companies in the industry such as Petrokemya, Safco, SADAF, Ibn Baytar, IBN Zahr, and Saudi Aramco. This has enabled me to gain a comprehensive understanding of the Oil & Gas sector and to effectively manage projects with a high degree of efficiency and professionalism.                  In addition, I possess the ability to support construction works, fabrication works, and manpower supply. This allows me to effectively manage projects from start to finish and to ensure that all aspects of the project are executed efficiently and effectively.                  Overall, my extensive experience and knowledge in the Oil & Gas industry, combined with my technical expertise, project management skills, and proficiency with MS Office Suite of tools make me a valuable asset to any team and an excellent candidate for any project in the industry.
              </p>

              <p className="mb-8">
                  Anis Uz Zaman
                </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default AboutContent;